.section-wrapper {
    position: relative;
    padding: 0 30px;
    width: 90%;
    margin: 60px auto 20px auto;
    /* box-shadow: 0 5px 5px #666; */
    background-color: #FFF;
    height: 100%;
}

.intro-image-wrapper {
    width: 240px;
    margin: 10px auto;
}

.intro-image-wrapper img {
    width: 100%;
    height: inherit;
}

.intro-text-wrapper h1 {
    font-size: 56px;
    text-align: left;
    margin-left: -4px;
    font-weight: 100;
    margin-top: 20px;
}

.testimonial-header h3 {
    font-size: 36px;
    text-align: center;
    font-weight: 100;
    margin-top: 20px;
    margin-bottom: 20px;
}

.intro-text-wrapper h3 {
    font-size: 42px;
}

.figure {
    width: 20%;
    margin: auto;
}

.figure img {
    width: 100%;
    margin: auto;
    aspect-ratio: 3/2;
    object-fit: contain;
}

.contact-wrapper a {
    color: #333;
    text-decoration: none;
    font-size: 12px;
    padding-right: 40px;
}

.contact-wrapper {
    margin-bottom: 10px;
    font-size: 12px;
    text-align: left;
}

.border-right {
    border-right: 1px solid #CCC;
}

.figure-caption {
    text-align: center;
    font-weight: 400;
    margin-top: 15px;
}

.experience-wrapper {
    margin-bottom: 60px;
}

.bio-description p {
    font-size: 14px;
}

.bio-description {
    text-align: left;
}

.contact-wrapper i {
    font-size: 20px;
    vertical-align: sub;
}

.contact-wrapper p {
    margin-bottom: 15px;
}

a .figure-caption {
    text-decoration: none;
    color: #333;
    font-size: 12px;
}

a:hover,
a:hover,
a:visited,
a:link,
a:active {
    text-decoration: none;
}

.card-deck {
    padding: 15px;
}

.text-center {
    text-align: center;
}

.skill {
    padding: 5px;
    font-size: 14px;
    color: #FFF;
    font-weight: bold;
    border-radius: 4px;
    width: 160px;
    margin: 20px 10px;
    background: rgb(27, 80, 128);
    background: linear-gradient(90deg, rgba(27, 80, 128, 1) 0%, rgba(27, 80, 128, 0.8603816526610644) 35%, rgba(27, 80, 128, 1) 100%);
}

.bg-primary {
    padding: 2px 7px !important;
    font-size: 11px !important;
    margin-bottom: 15px !important;
    margin-top: -10px !important;
    background-color: #212529 !important;
}