.videos-wrapper img {
    width: 100%;
    height: inherit;
}

.videos-wrapper h3 {
    margin:0;
    margin-bottom: 10px;
}

.videos-wrapper {
    margin-bottom: 40px;
}