body {
    /* background: #f7f7f7; */
}

blockquote {
    border-left: none;
    text-align: center;
}

.text-bold {
    font-weight: 400;
}

p  {
    font-size: 14px;
}