.header-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    color: #000;
    height: 60px;
    padding: 0 30px;
    z-index: 9999;
}

.header-wrapper .bg-light {
    background-color: #FFF !important;
}

.header-wrapper p,
.header-wrapper a {
    font-size: 12px;
    font-weight: 400;
}

.header-elements {
    height: 60px;
    line-height: 60px;
}

.header-elements i {
    font-size: 16px;
    vertical-align: text-bottom;
}

.header-wrapper a {
    color: #000;
    text-decoration: none;
    padding: 0 20px;
}

@media only screen and (max-width: 725px) {
    .left-header-content {
        display: none;
    }

    .right-header-content {
        text-align: center;
        float: none !important;
    }
}