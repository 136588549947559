.footer-wrapper {
    background-color: #757575f2;
    width: 100%;
    color: #FFF;
    height: 60px;
    padding: 0 30px;
}

.footer-wrapper p {
    text-align: center;
    padding-top: 20px;
}