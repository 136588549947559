.showcase-wrapper img {
    width: 100%;
    margin: auto;
    object-fit: cover;
    height: 150px;
}

.showcase-wrapper h3 {
    margin: 0;
    margin-bottom: 10px;
}

.showcase-wrapper p {
    font-size: 13px;
}

.showcase-wrapper {
    margin-bottom: 40px;
}

.showcase-wrapper table {
    font-size: 12px;
}

.showcase-wrapper .col-sm-4 {
    margin-bottom: 40px;
}

.showcase-section .card {
    height: 680px !important;
}