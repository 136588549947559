.bragging-wrapper img {
    width: 100%;
    margin: auto;
    object-fit: cover;
    height: 250px;
}

.bragging-section .card {
    height: 370px !important;
}

.bragging-wrapper h3 {
    margin: 0;
    margin-bottom: 10px;
}