.testimonial-image {
    width: 200px !important;
    margin: auto !important;
}

.testimonial-image img {
    border-radius: 280px !important;
    width: 100%;
}

.testimonial-content {
    text-align: left;
    padding-right: 40px;
}

.card {
    width: 100%;
}


.testimonial-content {
    font-size: 14px;
    font-style: italic;
    color: #555555;
    padding: 1.2em 30px 1.2em 75px;
    border-left: 8px solid #1b5f9b;
    line-height: 1.6;
    position: relative;
    background: #fcfcfc;
}

.testimonial-content::before {
    font-family: Arial;
    content: "\201C";
    color: #1b5f9b;
    font-size: 4em;
    position: absolute;
    left: 10px;
    top: -10px;
}

.testimonial-content::after {
    content: '';
}

.testimonial-content span {
    display: block;
    color: #333333;
    font-style: normal;
    font-weight: bold;
    margin-top: 1em;
}