body {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    letter-spacing: 0.5px;
}

.vertical-middle {
    vertical-align: middle;
}

.vertical-text-bottom {
    vertical-align: text-bottom;
}

.section-divider {
    background-color: #f0f0f0;
    padding: 10px 30px;
    margin-left: -30px;
    margin-right: -30px;
    color: #000;
    font-size: 24px;
    margin-top: 25px;
}

.page-title {
    padding: 10px;
    color: #000;
    text-align: center;
    font-size: 28px;
}

.font-bold {
    font-weight: bold;
}

.label {
    font-size: 10px;
    border-radius: 5px;
    padding: 1px 5px;
    color: #FFF;
    margin: 2px;
}

.js-label {
    background-color: #f7df1e;
}

.html-label {
    background-color: #ef652a;
}

.css-label {
    background-color: #3074b1;
}

.laravel-label {
    background-color: #fb503b;
}

.jquery-label {
    background-color: #2a97c1;
}

.php-label {
    background-color: #787ab7;
}

.ts-label {
    background-color: #007acc;
}

.net-label {
    background-color: #68217a;
}

.java-label {
    background-color: #f8981d;
}

.react-label {
    background-color: #61dafb;
}

.hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(82, 81, 81, 0.75), rgba(0, 0, 0, 0));
    margin-bottom: 35px;
    margin-top: 5px;
}

.figure {
    display: block;
}

.card {
    border: none;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.13);
}